import React, { useEffect } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import './Success.css'; // Ensure this CSS file is created and styled
import getUserData from '../../utils/userDataService';
import { useDispatch, useSelector } from 'react-redux';

const Success = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Function to parse query parameters from the URL
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  useEffect(() => {
    getUserData(dispatch);
  }, [dispatch]);

  // Extracting order_id and email from query params (optional)
  const queryParams = getQueryParams();
  const order_id = queryParams.get('order_id');

  const handleContinueShopping = () => {
    navigate('/'); // Redirect to home page or wherever you'd like
  };

  useEffect(() => {
    const loadTrustpilotInvitation = () => {
      if (typeof window.tp !== 'undefined') {
        const trustpilot_invitation = {
          recipientEmail: user?.email,
          recipientName: user?.fname,
          referenceId: order_id,
          source: 'InvitationScript',
        };
        window.tp('createInvitation', trustpilot_invitation); // Trigger Trustpilot review invitation
      } else {
        console.error('Trustpilot script not loaded or tp is not available');
      }
    };

    loadTrustpilotInvitation();
  }, [user, order_id]);

  return (
    <div className="success-container">
      <CheckCircleOutlined className="success-icon success-animated" />
      <h1 className="success-message">Payment Successful!</h1>
      <p className="success-description">
        Thank You for Your Order, {user?.fname}!
      </p>
      <Button type="primary" onClick={handleContinueShopping} className="success-button center">
        Continue Ordering
      </Button>

      {/* Enhanced Review Section */}
      <div className="review-section">
        <h2 className="review-title">We Value Your Feedback!</h2>
        <p className="review-subtitle">Your experience matters to us.</p>
        <p className="review-description">
          Please take a moment to share your thoughts about our service. Your feedback helps us improve!
        </p>
        <p className="review-link">
          <a href="https://www.trustpilot.com/review/topupplayground.com" target="_blank" rel="noopener noreferrer">
            Click here to leave a review
          </a>
        </p>
      </div>
    </div>
  );
};

export default Success;
