import React, { useEffect, useState } from "react";
import { Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import HeroSvg from "../../components/Layout/HeroSvg";
import SideMenu from "../../components/Header/SideMenu";
import getUserData from "../../utils/userDataService";
import ProductInfoContinueButton from "./ProductInfoContinueButton";
import { submitPaymentForm } from "./submitPaymentForm";
import { setUser } from "../../redux/features/userSlice";
import InstructionModal from "./InstructionModal";
import "./ProductInfoMobile.css";
import UpdateMobileNumberModal from "../../components/Modal/UpdateMobileNumberModal";

const ProductInfoMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [sideMenu, setSideMenu] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [product, setProduct] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [allowUser, setAllowUser] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(null);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("UPI");
  const [showPopupUpdateMobile, setShowUpdateMobileModal] = useState(false);
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [loader, setLoader] = useState(false);
  // GATEWAY
  const [gateway, setGateway] = useState("BARCODE");

  const onClose = () => {
    dispatch(setActiveButton("home"));
    return navigate("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, [params?.name]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
    // eslint-disable-next-line
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setOriginalPrice(pf);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setOriginalPrice(pf);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-product-by-name",
        {
          name: params.name,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultOriginalPrice = res.data.data?.cost?.[0]?.profit;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
        setOriginalPrice(defaultOriginalPrice);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, [params?.name]);

  async function handleCheckPlayer() {
    setCheckLoading(true);
    if (userId === "" || zoneId === "") {
      setCheckLoading(false);
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    setCheckLoading(true);
    try {
      const idPro = productId.split("&")[0];
      const object = {
        region: "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: "13",
      };
      const res = await axios.post("/api/payment/get-role", object, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        if (product?.use === res.data.use) {
          setAllowUser(true);
        } else {
          setAllowUser(false);
        }
      } else {
        setPlayerCheck(null);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCheckLoading(false);
      setError(false);
    }
  }

  //* ================================= ORDER SYSTEM ==========================================
  function checkPlaceOrder(e, paymentMode, orderId) {
    setMode(paymentMode);
    if (product?.playerCheckBtn === "yes") {
      if (playerCheck === null) {
        setShowConfirmModal(false);
        setError(true);
        return message.error("Validate username");
      }
    }
    if (product?.api === "no") {
      if (userId === "") {
        setShowConfirmModal(false);
        setError(true);
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
    } else if (product.api === "yes" && product?.apiName === "indianSmart") {
      const userConfirmed = window.confirm("is your instagram account public?");
      if (!userConfirmed) {
        return message.error("Make your insta account public");
      }
      if (userId === "") {
        return message.error("Enter Link");
      }
    } else if (product.api === "yes" && product?.apiName === "primeSmm") {
      if (userId === "") {
        return message.error("Enter Link");
      }
      const userConfirmed = window.confirm("is your instagram account public?");
      if (!userConfirmed) {
        return message.error("Make your insta account public");
      }
    } else if (product?.apiName === "moogold") {
      if (product?.fields?.length === 1) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      } else if (product?.fields?.length === 2) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (paymentMode === "UPI") {
          handleYokcashUpiOrder(e, orderId);
        } else {
          handleYokcashWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "smileOne") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeSmileUpi(e, orderId);
          } else if (gateway === "PAYU") {
            handleSmileOneUpiOrder(e, orderId);
          }
        } else {
          handleSmileOneWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "moogold") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeMoogoldUpi(e, orderId);
          } else if (gateway === "PAYU") {
            handleMoogoldUpiOrder(e, orderId);
          }
        } else {
          handleMoogoldWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "indianSmart") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodeIndianSmartUpiOrder(e, orderId);
          } else if (gateway === "PAYU") {
            handleIndianSmartUpiOrder(e, orderId);
          }
        } else {
          handleIndianSmartWalletOrder(e, orderId);
        }
      } else if (product?.apiName === "primeSmm") {
        if (paymentMode === "UPI") {
          if (gateway === "BARCODE") {
            handleBarcodePrimeSmmUpiOrder(e, orderId);
          } else if (gateway === "PAYU") {
            handlePrimeSmmUpiOrder(e, orderId);
          }
        } else {
          handlePrimeSmmWalletOrder(e, orderId);
        }
      }
    } else {
      if (paymentMode === "UPI") {
        if (gateway === "BARCODE") {
          handleBarcodeUpiOrder(e, orderId);
        } else if (gateway === "PAYU") {
          handleUpiOrder(e, orderId);
        }
      } else {
        handleWalletOrder(e, orderId);
      }
    }
  }

  // Yokcash
  async function handleYokcashUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/yokcash/check-yokcash-upi-order?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "%" +
          zoneId.trim() +
          "%" +
          productId +
          "%" +
          product?.name,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleYokcashWalletOrder(e, orderId) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      setShowConfirmModal(false);
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "%" +
          zoneId.trim() +
          "%" +
          productId +
          "%" +
          product?.name,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/yokcash/place-yokcash-from-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/user-dashboard");
        setLoading(false);
        setLoader(false);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  // SMILE
  const handleSmileOneUpiOrder = async (e, orderId) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/smile/success",
        // failure_URL: "http://localhost:8080/api/smile/failure",
        success_URL: "https://topupplayground.com/api/smile/success",
        failure_URL: "https://topupplayground.com/api/smile/failure",
        udf1: product?.region + "@" + originalPrice, // region
        udf2: productId, // productId
        udf3: userId, // userId
        udf4: zoneId, // zoneId
        udf5: amount, // amount
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e, orderId) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    setLoader(true);
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        name: user?.fname,
        price: selectedPrice,
        originalPrice: originalPrice,
      };

      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };
  const handleBarcodeSmileUpi = async (e, orderId) => {
    e.preventDefault();
    try {
      setLoader(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      const response = await axios.post("/api/smile/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };

  // MOOGOLD
  async function handleMoogoldUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/moogold/success",
        // failure_URL: "http://localhost:8080/api/moogold/failure",
        success_URL: "https://topupplayground.com/api/moogold/success",
        failure_URL: "https://topupplayground.com/api/moogold/failure",

        // product_details: product?.categoryId + "@" + product?.gameName,
        udf1: product?.gameName + "@" + originalPrice, // product gameName
        udf2: productId, // productId
        udf3: userId,
        udf4: zoneId,
        udf5: amount,
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e, orderId) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      setLoader(true);
      const orderObject = {
        api: product?.api,
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.categoryId + "@" + product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        originalPrice: originalPrice,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  }
  async function handleBarcodeMoogoldUpi(e, orderId) {
    e.preventDefault();
    try {
      setLoader(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/moogold/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          originalPrice,
      };

      const response = await axios.post("/api/moogold/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  }

  // INDIANSMART
  async function handleIndianSmartUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/indian/success",
        // failure_URL: "http://localhost:8080/api/indian/failure",
        success_URL: "https://topupplayground.com/api/indian/success",
        failure_URL: "https://topupplayground.com/api/indian/failure",
        udf1: userId,
        udf2: productId,
        udf3: amount,
        udf4: originalPrice,
        udf5: "",
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleIndianSmartWalletOrder(e, orderId) {
    e.preventDefault();
    try {
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error("Balance is less for this order");
      }
      setLoader(true);
      const orderObject = {
        orderId: orderId,
        price: selectedPrice,
        pname: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId,
        productid: productId,
        amount: amount,
        originalPrice: originalPrice,
      };
      const res = await axios.post("/api/indian/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  }
  async function handleBarcodeIndianSmartUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/indian/status?orderId=${orderId}&link=${userId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };

      setLoader(true);
      const res = await axios.post("/api/indian/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
      } else {
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  }

  // PRIME SMM
  async function handlePrimeSmmUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/indian/success",
        // failure_URL: "http://localhost:8080/api/indian/failure",
        success_URL: "https://topupplayground.com/api/prime/success",
        failure_URL: "https://topupplayground.com/api/prime/failure",
        udf1: userId,
        udf2: productId,
        udf3: amount,
        udf4: originalPrice,
        udf5: "",
      };
      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {}
  }
  async function handlePrimeSmmWalletOrder(e, orderId) {
    e.preventDefault();
    try {
      if (parseInt(balance) < parseInt(selectedPrice)) {
        return message.error("Balance is less for this order");
      }
      const orderObject = {
        orderId: orderId,
        price: selectedPrice,
        pname: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId,
        productid: productId,
        amount: amount,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/prime/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoader(false);
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleBarcodePrimeSmmUpiOrder(e, orderId) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/prime/status?orderId=${orderId}&link=${userId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/prime/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(res.data.message);
        navigate("/failure");
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // MANUAL ORDER
  const handleUpiOrder = async (e, orderId) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        // product_details: product?.region + "#" + amount,
        customer_name: user?.fname,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        // success_URL: "http://localhost:8080/api/manual/success",
        // failure_URL: "http://localhost:8080/api/manual/failure",
        success_URL: "https://topupplayground.com/api/manual/success",
        failure_URL: "https://topupplayground.com/api/manual/failure",
        udf1: userId, // userId
        udf2: amount, // amount
        udf3: originalPrice,
        udf4: "",
        udf5: "",
      };

      setLoader(true);
      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoader);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e, orderId) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      setShowConfirmModal(false);
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
        originalPrice: originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };
  const handleBarcodeUpiOrder = async (e, orderId) => {
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/manual/status?orderId=${orderId}`,
        txn_note:
          userId.trim() + "@" + productId + "@" + amount + "@" + originalPrice,
      };
      setLoader(true);
      const res = await axios.post("/api/manual/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        window.location.href = res.data.data.results.payment_url;
      } else {
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      // setLoader(false);
    }
  };

  return (
    <>
      <Layout>
        <div className={`account-modal-container d-lg-none d-md-none `}>
          <HeroSvg />
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center" onClick={onClose}>
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <h2 className="mb-0">Checkout</h2>
            </div>
            <div
              className="top-right-content box-2 center"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <svg
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-list text-white"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </div>
          </div>

          <div className="checkout-product-container px-3 mb-5">
            {/* HEADER TOP SECTION */}
            <div className="checkout-product-content px-3 py-3 mb-4">
              <div className="center gap-2">
                <div className="product-image">
                  <img
                    src={`https://topupplayground.com/${product?.image}`}
                    alt="loading-img"
                    loading="lazy"
                  />
                </div>
                <div className="align-self-center ps-1">
                  <h5 className="mb-1">{product?.name}</h5>
                  <p
                    className={`mb-0 font-14 opacity-70 ${
                      playerCheck ? "text-success" : "text-dark"
                    }`}
                  >
                    {playerCheck ? (
                      <>
                        {capitalizeFirstLetter(playerCheck)}
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-patch-check-fill ms-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                        </svg>
                      </>
                    ) : (
                      "N.A"
                    )}
                  </p>
                </div>
              </div>
              <div
                className="p-2"
                onClick={() => {
                  setShowInstructions(true);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="text-danger"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
              </div>
            </div>

            {/* FORM SECTION */}
            <div
              className={`checkout-product-content form-content flex-column mb-3 position-relative`}
            >
              <div className="card-header w-100">Enter your informations</div>
              {product?.apiName === "smileOne" ? (
                <form
                  className="content w-100 center flex-column gap-2 w-100 p-3"
                  onSubmit={handleSubmit}
                >
                  <div className="form-custom w-100">
                    <div className="form-input w-100">
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="form-input-svg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                      </svg>
                      <input
                        type="text"
                        autoComplete="off"
                        name="userId"
                        placeholder={
                          product?.tagOne ? product?.tagOne : "USER ID"
                        }
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                        className={`form-control w-100  ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                      {product?.playerCheckBtn === "yes" && (
                        <button
                          type="submit"
                          className="validateBtn text-white py-1 px-3 rounded-3"
                          id="validateBtn"
                          onClick={handleCheckPlayer}
                        >
                          {checkLoading ? (
                            <div
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Validate"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className={`invalid-feedback mb-2 ${error && "d-flex"}`}>
                    Please enter your{" "}
                    {product?.tagOne ? product?.tagOne : "USER ID"}
                  </div>
                  <div className="form-custom w-100">
                    <div className="form-input w-100">
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="form-input-svg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                      </svg>
                      <input
                        type="text"
                        autoComplete="off"
                        name="zoneid"
                        placeholder={
                          product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"
                        }
                        onChange={(e) => setZoneId(e.target.value)}
                        value={zoneId}
                        id="serverId"
                        className={`form-control w-100  ${
                          error ? "is-invalid" : ""
                        }`}
                      />
                      <span>(required)</span>
                    </div>
                  </div>
                  <div className={`invalid-feedback mb-2 ${error && "d-flex"}`}>
                    Please enter your{" "}
                    {product?.tagTwo ? product?.tagTwo : "(  ZONE ID  )"}
                  </div>
                  {error && (
                    <span className="text-dark w-100 bg-warning px-1 py-1 rounded-pill text-center">
                      Check your username by clicking on validate button
                    </span>
                  )}
                  <span className="text-success user-name">
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                </form>
              ) : (
                <>
                  {product?.apiName === "moogold" ? (
                    product?.fields?.length === 2 &&
                    typeof product.servers === "object" ? (
                      <form
                        className="content w-100 center flex-column gap-2 w-100 p-3"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-custom w-100">
                          <div className="form-input w-100">
                            <svg
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="form-input-svg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                            </svg>
                            <input
                              type="text"
                              autoComplete="off"
                              name="userId"
                              onChange={(e) => setUserId(e.target.value)}
                              value={userId}
                              placeholder={
                                product?.tagOne ? product?.tagOne : "USER ID"
                              }
                              className={`form-control w-100  ${
                                error ? "is-invalid" : ""
                              }`}
                            />
                          </div>
                        </div>
                        <div
                          className={`invalid-feedback mb-2 ${
                            error && "d-flex"
                          }`}
                        >
                          Please enter your{" "}
                          {product?.tagOne ? product?.tagOne : "USER ID"}
                        </div>
                        <div className="form-custom w-100">
                          <div className="form-input w-100">
                            <svg
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="form-input-svg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                            </svg>
                            <select
                              onChange={(e) => setZoneId(e.target.value)}
                              className={`form-control w-100 ${
                                error ? "is-invalid" : ""
                              }`}
                              name="zoneId"
                            >
                              <option value="">Select Server</option>
                              {Object.entries(product?.servers).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div
                          className={`invalid-feedback mb-2 ${
                            error && "d-flex"
                          }`}
                        >
                          Please enter your zone id
                        </div>
                      </form>
                    ) : (
                      <>
                        {product?.fields.length === 2 &&
                        typeof product.servers === "string" ? (
                          <form
                            className="content w-100 center flex-column gap-2 w-100 p-3"
                            onSubmit={handleSubmit}
                          >
                            <div className="form-custom w-100">
                              <div className="form-input w-100">
                                <svg
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="form-input-svg"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                </svg>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="userId"
                                  placeholder={
                                    product?.tagOne
                                      ? product?.tagOne
                                      : "USER ID"
                                  }
                                  onChange={(e) => setUserId(e.target.value)}
                                  value={userId}
                                  className={`form-control w-100  ${
                                    error ? "is-invalid" : ""
                                  }`}
                                />
                                {product?.playerCheckBtn === "yes" && (
                                  <button
                                    type="submit"
                                    className="validateBtn text-white py-1 px-3 rounded-3"
                                    id="validateBtn"
                                    onClick={handleCheckPlayer}
                                  >
                                    {checkLoading ? (
                                      <div
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Validate"
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div
                              className={`invalid-feedback mb-2 ${
                                error && "d-flex"
                              }`}
                            >
                              Please enter your{" "}
                              {product?.tagOne ? product?.tagOne : "USER ID"}
                            </div>
                            <div className="form-custom w-100">
                              <div className="form-input w-100">
                                <svg
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="form-input-svg"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                </svg>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="zoneid"
                                  placeholder={
                                    product?.tagTwo
                                      ? product?.tagTwo
                                      : "(  ZONE ID  )"
                                  }
                                  onChange={(e) => setZoneId(e.target.value)}
                                  value={zoneId}
                                  className={`form-control w-100  ${
                                    error ? "is-invalid" : ""
                                  }`}
                                />
                                <span>(required)</span>
                              </div>
                            </div>
                            <div
                              className={`invalid-feedback mb-2 ${
                                error && "d-flex"
                              }`}
                            >
                              Please enter your{" "}
                              {product?.tagTwo
                                ? product?.tagTwo
                                : "(  ZONE ID  )"}
                            </div>
                            {error && (
                              <span className="text-dark w-100 bg-warning px-1 py-1 rounded-pill text-center">
                                Check your username by clicking on validate
                                button
                              </span>
                            )}
                            <span className="text-success user-name">
                              {playerCheck && "Username: " + playerCheck}
                            </span>
                          </form>
                        ) : (
                          <form
                            className="content w-100 center flex-column gap-2 w-100 p-3"
                            onSubmit={handleSubmit}
                          >
                            <div className="form-custom w-100">
                              <div className="form-input w-100">
                                <svg
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="form-input-svg"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                </svg>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  name="userId"
                                  placeholder={
                                    product?.tagOne
                                      ? product?.tagOne
                                      : "(  USER ID  )"
                                  }
                                  onChange={(e) => setUserId(e.target.value)}
                                  value={userId}
                                  className={`form-control w-100  ${
                                    error ? "is-invalid" : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div
                              className={`invalid-feedback mb-2 ${
                                error && "d-flex"
                              }`}
                            >
                              Please enter your{" "}
                              {product?.tagOne
                                ? product?.tagOne
                                : "(  USER ID  )"}
                            </div>
                          </form>
                        )}
                      </>
                    )
                  ) : (
                    <form
                      className="content w-100 center flex-column gap-2 w-100 p-3"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-custom w-100">
                        <div className="form-input w-100">
                          <svg
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="form-input-svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                          </svg>
                          <input
                            type="text"
                            autoComplete="off"
                            name="userId"
                            placeholder={`${product?.tagOne}`}
                            onChange={(e) => setUserId(e.target.value)}
                            value={userId}
                            className={`form-control w-100  ${
                              error ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div
                        className={`invalid-feedback mb-2 ${error && "d-flex"}`}
                      >
                        Please enter your {product?.tagOne}
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>

            {/* PRODUCT SECTION */}
            <div
              className={`checkout-product-content form-content flex-column position-relative`}
            >
              <div className="card-header w-100">Select the Products</div>
              <div className={`checkout-product-content-package-container row w-100 py-2 px-1 ${!product?.cost && "loading"}`} style={{height: `${!product?.cost? "400px" : "auto"}`}}>
                {product?.cost?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setAmount(item.amount);
                        setPriceAndId(item.amount);
                      }}
                      key={index}
                      className={`col-6 px-0 position-relative my-2`}
                    >
                      <div
                        className={`product-info-item-card mx-2 ${
                          amount === item?.amount && "highlighted"
                        } overflow-hidden`}
                      >
                        <div className={`px-3 ${amount === item?.amount? "bg-highlighted" : "bg-white"} py-3 d-flex justify-content-between align-items-center w-100 position-relative`}>
                          <span className="price">{item.amount}</span>
                          <span className="icon">
                            {item?.pimg && (
                              <img width={40} src={item?.pimg} alt="" loading="lazy"/>
                            )}
                          </span>
                          {amount === item?.amount && (
                            <>
                              <span
                                className="position-absolute"
                                style={{
                                  background: "rgb(250, 51, 51)",
                                  width: "40px",
                                  height: "17px",
                                  marginTop: "0",
                                  top: "-5px",
                                  right: "-15px",
                                  transform: "rotate(40deg)",
                                }}
                              ></span>
                              <svg
                                width="13"
                                height="13"
                                fill="currentColor"
                                className="text-dark position-absolute end-0 top-0 text-white"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                              </svg>
                            </>
                          )}
                        </div>
                      </div>
                      <span
                        className="position-absolute top-0 left-100 translate-middle badge rounded-pill"
                        style={{ background: "rgb(250, 51, 51)", left: "20px" }}
                      >
                        99%
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {
          <ProductInfoContinueButton
            loader={loader}
            checkPlaceOrder={checkPlaceOrder}
            selectedPrice={selectedPrice}
            product={product}
            playerCheck={playerCheck}
            amount={amount}
            setMode={setMode}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            userId={userId}
            allowUser={allowUser}
            setShowUpdateMobileModal={()=>{setShowUpdateMobileModal(!showPopupUpdateMobile)}}
          />
        }
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
        <InstructionModal
          showInstructions={showInstructions}
          setShowInstructions={setShowInstructions}
          product={product}
        />
        {showPopupUpdateMobile && <UpdateMobileNumberModal path={location.pathname} handleClose={()=>{setShowUpdateMobileModal(!showPopupUpdateMobile)}}/>}
      </Layout>
    </>
  );
};

export default ProductInfoMobile;
