import React, { useState } from 'react';
import "./NavigationButtons.css";
import { useNavigate } from 'react-router-dom';
import NotLoginPopupModal from './Modal/NotLoginPopupModal';
import { useDispatch, useSelector } from 'react-redux';
import AddMoneyModal from './Modal/AddMoneyModal';
import { setActiveButton } from '../redux/features/footerMenuSlice';
import { CreditCardSvg, TrophySvg } from './AllSvgModulesExport/LoginSvg';

const NavigationButtons = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const [showAddMoneyModal, setShowAddMoneyModal] = useState(false);
    
  return (
    <>
    <div className='d-lg-none d-md-none navigation-button mx-3'>
        <div className='center flex-column' >
            <div className='navigation-button-div shadow' onClick={()=>{!user? setShowModal(true) : setShowAddMoneyModal(true)}}>
                <span className='add-icon center fw-bold'>+</span>
            </div>
            <span className='navigation-button-name'>Add Money</span>
        </div>
        <div className='center flex-column' onClick={()=>{
            navigate('/reports?type=payment');
            dispatch(setActiveButton("reports"));
        }}>
            <div className='navigation-button-div shadow'>
                <svg width="25" height="25" fill="#70b452" className="bi bi-wallet2" viewBox="0 0 16 16">
                    <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"/>
                </svg>
            </div>
            <span className='navigation-button-name'>Payments</span>
        </div>
        <div className='center flex-column' onClick={()=>{
            navigate('/reports?type=purchase');
            dispatch(setActiveButton("reports"));
        }}>
            <div className='navigation-button-div shadow'>
                <CreditCardSvg width={25} color="#4a89dc "/>
            </div>
            <span className='navigation-button-name'>Purchase</span>
        </div>
        <div className='center flex-column'>
            <div className='navigation-button-div shadow' onClick={()=>{navigate('/leaderboard')}}>
                <TrophySvg width={25} color="#aa8e69 "/>
            </div>
            <span className='navigation-button-name'>Leaderboard</span>
        </div>
    </div>
    <NotLoginPopupModal string="Please login to see your wallet details." show={showModal} onClose={()=> setShowModal(false)}/>
    <AddMoneyModal showAddMoneyModal={showAddMoneyModal} setShowAddMoneyModal={setShowAddMoneyModal}/>
    </>
  )
}

export default NavigationButtons
