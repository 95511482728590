import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import "./AddBlog.css";
import { useParams, useNavigate } from "react-router-dom";

const AddBlog = () => {
    const [allBlogs, setAllBlogs] = useState([]);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState('');
    const [description, setDescription] = useState('');
    const [content, setContent] = useState('');
    const navigate = useNavigate(); // Updated
    const { id } = useParams(); // For updating
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            // Fetch blog data for update
            axios.get(`/api/blogs/${id}`)
                .then(response => {
                    const blog = response.data;
                    setTitle(blog.title);
                    setCategory(blog.category);
                    setImage(blog.image); // Set the current image URL for editing
                    setDescription(blog.description);
                    setContent(blog.content);
                    if(blog){
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error)
                    setLoading(false);
                });
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("category", category);
        formData.append("description", description);
        formData.append("content", content);
        
        // Append image file only if it's a file object (not a URL)
        if (image && typeof image !== 'string') {
            formData.append("image", image);
        }
        setLoading(true);
        try {
            if (id) {
                // Update blog
                await axios.put(`/api/blogs/update/${id}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    }
                });
                message.success('Blog updated successfully!');
            } else {
                // Add new blog
                await axios.post('/api/blogs/add', formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    }
                });
                message.success('Blog added successfully!');
            }
            getAllBlogs();
        } catch (error) {
            console.error(error);
            alert('Failed to save blog');
        } finally {
            setLoading(false);
        }
    };    
    

    const handleDelete = async (blogId) => {
        const confirm = window.confirm("Are you sure to delete?");
        if (confirm) {
            setLoading(true);
            try {
                const res = await axios.delete(`/api/blogs/delete/${blogId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (res.data.success) {
                    message.success(res.data.message);
                    getAllBlogs();
                } else {
                    message.error(res.data.message);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }

    const getAllBlogs = async () => {
        try {
            const res = await axios.get("/api/blogs");
            if(res){
                setAllBlogs(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddBlog = ()=>{
        navigate("/add-blog");
        setImage('');
        setTitle('');
        setCategory('');
        setDescription('');
        setContent('');
    }

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <AdminLayout>
        <div className="admin-users-container">
            <div className="page-title mb-3">
            <h3 className="m-0">{id ? 'Update Blog' : 'Add Blog'}</h3>
            {id && <button onClick={handleAddBlog}>Add+</button>}
            </div>
            {!loading? <div className="bg-light p-3 add-blog">
                <div className="">
                    <form className="row p-0" onSubmit={handleSubmit}>
                        <div className="col-12 col-lg-4">
                            <input type="text" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} required />
                        </div>
                        <div className="col-12 col-lg-4">
                            <select 
                                value={category} 
                                onChange={(e) => setCategory(e.target.value)} 
                                required
                            >
                                <option value="" disabled>Select Category</option>
                                <option value="Mobile Legend">Mobile Legend</option>
                                <option value="PUBG">PUBG</option>
                                <option value="BGMI">BGMI</option>
                                <option value="Clash of Clans">Clash of Clans</option>
                                <option value="Genshin Impact">Genshin Impact</option>
                                <option value="Honkai Star Rail">Honkai Star Rail</option>
                                <option value="Brawl Stars">Brawl Stars</option>
                                <option value="Honor of kings">Honor of kings</option>
                                <option value="Call of Duty">Call of Duty</option>
                                <option value="Clash Royale">Clash Royale</option>
                                <option value="Valorant ">Valorant </option>
                                <option value="Zenless Zone Zero">Zenless Zone Zero</option>
                                <option value="Free Fire">Free Fire</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-4">
                            <input 
                                type="file" 
                                accept="image/*"
                                onChange={(e) => setImage(e.target.files[0])} 
                            />
                            {image && typeof image === 'string' && image.trim() !== '' && (
                                <div className="image-preview">
                                    <img width={100} src={image} alt="Blog Image Preview" />
                                </div>
                            )}
                        </div>
                        <div className="col-12 col-lg-6">
                            <textarea value={description} placeholder="Description" onChange={(e) => setDescription(e.target.value)} required></textarea>
                        </div>
                        <div className="col-12 col-lg-6">
                            <textarea value={content} placeholder="Content" onChange={(e) => setContent(e.target.value)} required></textarea>
                        </div>
                        <div className="col-12 col-lg-12">
                            <button type="submit">{id ? 'Update Blog' : 'Add Blog'}</button>
                        </div>
                    </form>
                </div>
                {/* Display blogs in a table */}
                {allBlogs.length > 0 && (
                    <table className="table table-border mt-4 mx-0">
                        <thead>
                            <tr>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allBlogs.map(blog => (
                            <tr key={blog._id}>
                                <td>{blog.title}</td>
                                <td>{blog.category}</td>
                                <td className="d-flex gap-2">
                                    <button className="delete-btn" onClick={() => handleDelete(blog._id)}><DeleteIcon /></button>
                                    <button className="delete-btn" onClick={() => navigate(`/update-blog/${blog._id}`)}>Edit</button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            :
            <div className="text-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
            }
        </div>
    </AdminLayout>
  );
};

export default AddBlog;
