import React, { useEffect, useRef, useState } from 'react';
import "./Leaderboard.css";
import { Link, useNavigate } from 'react-router-dom';
import HeroSvg from '../components/Layout/HeroSvg';
import SideMenu from '../components/Header/SideMenu';
import { useDispatch } from 'react-redux';
import { setActiveButton } from '../redux/features/footerMenuSlice';
import axios from 'axios';
import { SmileEmojiSvg } from '../components/AllSvgModulesExport/LoginSvg';
import gold from "../img/leaderboard/gold.png";
import silver from "../img/leaderboard/silver.png";
import bronze from "../img/leaderboard/bronze.png";
import { io } from "socket.io-client";

const socket = io("http://localhost:8080"); 

const Leaderboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sideMenu, setSideMenu] = useState(false);

    const onClose = () => {
        navigate('/');
        dispatch(setActiveButton('home'));
        localStorage.setItem('activeButton', 'home');
    };

    const [topUsers, setTopUsers] = useState([]);
    const [timeLeft, setTimeLeft] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let interval;
        setLoading(true); 
        const fetchTopUsers = async () => {
            try {
                const { data } = await axios.get('/api/user/top-users', {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });
                setTopUsers(data);
                if (data.length > 0) {
                    calculateTimeLeft(new Date(data[0].weekEnd));
                }
            } catch (error) {
                console.error('Error fetching top users', error);
            } finally {
                setTimeout(() => {
                    setLoading(false); 
                }, 500);
            }
        };

        const calculateTimeLeft = (weekEnd) => {
            interval = setInterval(() => {
                const now = new Date();
                const difference = weekEnd - now;
                
                if (difference > 0) {
                    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                    const minutes = Math.floor((difference / 1000 / 60) % 60);
                    const seconds = Math.floor((difference / 1000) % 60);
                    setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                } else {
                    clearInterval(interval);
                    setTimeLeft('Resetting...');
                    fetchTopUsers(); // Fetch again after reset
                }
            }, 1000);
        };
    
        fetchTopUsers();

        socket.on('updateTopUsers', (updatedTopUsers) => {
            console.log("Received updated top users:", updatedTopUsers); // Debugging log
            // Handle updated top users here, e.g., update state
        });

        return () => {
            clearInterval(interval);
            socket.off('updateTopUsers');
        };
    }, []);


    const getUserImage = (index, userName) => {
        if (index === 0) {
          return <img src={gold} width="45"alt="gold medal" loading="lazy"/>;
        } else if (index === 1) {
          return <img src={silver} width="45" alt="silver medal" loading="lazy"/>;
        } else if (index === 2) {
          return <img src={bronze} width="45" alt="bronze medal" loading="lazy"/>;
        } else {
          return (
            <div className="default-box rounded-s" >
              {userName?.charAt(0)?.toUpperCase()}
            </div>
          );
        }
    };

    const truncateName = (name, maxLength = 20) => {
        return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
    };
      

  return (
    <>
     <div className="announcement-container d-lg-none d-md-none bg-success">
        <HeroSvg color="red"/>
        <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center shadow" onClick={onClose}>
                  <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                  <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                  </svg>
              </div>
              <h2 className="mb-0 text-white">Leaderboard</h2>
            </div>
            <div className="top-right-content box-2 center shadow" onClick={() => setSideMenu(!sideMenu)}>
              <svg width="18" height="18" fill="currentColor" className="bi bi-list text-white" viewBox="0 0 16 16">
                  <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
              </svg>
            </div>
        </div>

        <div className="announcement-bottom-container leaderboard px-3 my-0">
            <div className={`announcement-bottom-content-issue-facing bg-color ${!loading? 'ps-3 pe-2 py-1' : 'shadow-none'} mb-4  position-relative`}>
                <div className={`content px-0 ${loading && 'loading'}`} style={{ minHeight: `${loading ? '75px' : 'auto'}` }}>
                {!loading && (
                    <div className="d-flex">
                        <div className="align-self-center no-click">
                            {/* Custom animated emoji */}
                            <SmileEmojiSvg width={50} />
                        </div>
                        <div className="align-self-center no-click">
                            <p className="color-white mb-0 ps-2 pe-0 line-height-s">
                                <span>The Leaderboard challenge will reset in</span>
                                <br />
                                {timeLeft? <span>{timeLeft}</span> : <span>Loading...</span>}
                            </p>
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
        
        <div className="announcement-bottom-container leaderboard px-3 my-0">
            <div className={`announcement-bottom-content-issue-facing bg-light ${!loading && 'p-0'} mb-4 py-0 position-relative`}>
                <div className={`content top-users-list p-0 py-2 ${loading && 'loading'}`} style={{ minHeight: '400px' }}>
                {!loading && (
                    <div>
                    {Array.isArray(topUsers) && topUsers?.length > 0 ? (
                        topUsers?.map((user, index) => (
                        <div className="row py-3 px-3 single-top-user" key={index}>
                            <div className="d-flex px-3 gap-3">
                                <div className="align-self-center">
                                    {getUserImage(index, user?.name)}
                                </div>
                                <div className="align-self-center">
                                    <h5 className="mb-0">{user?.name}</h5>
                                </div>
                                <div className="align-self-center ms-auto text-end">
                                    <h4 className="pt-1 mb-n1 color-green-dark no-wrap">₹ {user?.totalSpent}</h4>
                                </div>
                            </div>
                        </div>
                        ))
                    ) : (
                        <p className='text-center fw-bold mt-5'>No top users found</p>
                    )}
                    </div>
                )}
                </div>
            </div>
        </div>

        <span className="center mt-4">
          <small>
            <p className='mb-0 fw-bold text-white'>All Rights Reserved © 2024 | TOPUP PLAYGROUND</p>
            <p className='mb-0'>
              <span className='text-white'>Website designed & developed by</span>
              <Link
                target="_blank"
                className="text-warning"
                to="https://wa.me/918624887338"
              >
                ~@waleedsdev
              </Link>
            </p>
          </small>
        </span>  
      </div>
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default Leaderboard;
